const doan1 = require('../images/menuItems/doan/doan1.jpg')
const doan2 = require('../images/menuItems/doan/doan2.jpg')
const doan3 = require('../images/menuItems/doan/doan3.jpg')
const doan4 = require('../images/menuItems/doan/doan4.jpg')
const doan5 = require('../images/menuItems/doan/doan5.jpg')

export const thucDonDoan = [
    {
      name: "1",
      image: doan1
    },
    {
      name: "2",
      image: doan2
    },
    {
      name: "3",
      image: doan3
    },
    {
      name: "4",
      image: doan4
    },
    {
      name: "5",
      image: doan5
    }
   ];

