import React from 'react'
import "../styles/MenuDetail.css";





const MenuDoan = (props) =>{



 return(


         <img className='md' src={props.pic} alt='shirt' />
         

 )
}

export default MenuDoan