import tiec1 from '../images/menuItems/tiec/tiec1.jpg';
import tiec2 from '../images/menuItems/tiec/tiec2.jpg';

 export const thucDonTiec = [
  {
    name: "1",
    image: tiec1
  },
  {
    name: "2",
    image: tiec2
  }
 ];